import React from "react";
import Slider from "react-slick";
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import  "../components/scss/_carousel.scss"

/* eslint-disable */

class BlogSlider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            slides: this.props.sliderItems,
        };
        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    prev() {
        this.slider.slickPrev();
    }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 400,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: { unslick: true }
        }
      ]
    };
    return (
      <div className="carousel-slider">
        <Slider ref={slider => (this.slider = slider)} {...settings}>
    {this.state.slides.map(function(slide) {
        return (
            <div className="columns slide-wrapper" key={slide.title}>
                <div className="column">
                    <div className="columns blog-post">
                      <div className="column" style={{paddingBottom: `0`}}>
                      <a href={`${slide.linkurl}`} target="_blank" rel="noopener noreferrer"><PreviewCompatibleImage imageInfo={slide} /></a>
                      </div>
                      <div className="column">
                        <ul className="features" style={{
                          fontSize:'0.8rem',
                          lineHeight: '1.5',
                          fontWeight: '400',
                          listStyleType: 'none',
                          marginLeft: '0',
                      }}>
                          <li className="carousel--quote"> {slide.title}</li>
                          <li><a href={`${slide.linkurl}`} className="btn-underline" target="_blank" rel="noopener noreferrer">Read the article</a></li>
                      </ul>
                      </div>
                    </div>
                </div>
            </div>
        );
      })}
  </Slider>
      </div>
    );
  }
}

export default BlogSlider
