import React, { useState } from 'react'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { Waypoint } from 'react-waypoint'
import { animated, useSpring, config } from 'react-spring'

const Awards = ({awardItems}) => {
  const [on, toggle] = useState(false);
  const slideup = useSpring({
    opacity: on ? 1 : 0,
    transform: on ? 'translate3d(0,0,0)' : 'translate3d(0, 50%, 0)',
    config: config.wobbly
  });
  
  return (    
    <div className="column is-12">
      <Waypoint 
        bottomOffset="30%"
        onEnter={() => { 
          if (!on) toggle(true);
        }} 
        onLeave={() => { 
          if (on) toggle(false);
        }} 
      />
      <div className="columns">
        <animated.div className="column is-12 has-text-centered" style={slideup}>
            <div style={{
                width: '100px',
                maxHeight: '300px',
                display: 'inline-block',
              }}
              >
            <PreviewCompatibleImage imageInfo={awardItems.image1} />
          </div>
        </animated.div>
      </div>                        
      <div className="columns has-text-centered">
        <animated.div className="column is-4" style={slideup}>
            <div className="hm-desktop" style={{
                width: '80px',
                maxHeight: '300px',
                display: 'inline-block',
              }}
              >
              <PreviewCompatibleImage imageInfo={awardItems.image2} />
            </div>
        </animated.div>
        <div className="column is-4">
            <div className="awards__text">
              <h3>{awardItems.header}</h3>
              <p>{awardItems.blurb}</p>
            </div>
        </div>
        <animated.div className="column is-4" style={slideup}>
          <div className="hm-desktop" style={{
              width: '100px',
              maxHeight: '300px',
              display: 'inline-block',
            }}
            >
            <PreviewCompatibleImage imageInfo={awardItems.image3} />
          </div>
        </animated.div>
      </div>
      <div className="columns">
        <animated.div className="column is-12 has-text-centered" style={slideup}>
            <div className="hm-desktop" style={{
                width: '120px',
                maxHeight: '300px',
                display: 'inline-block',
              }}
              >
            <PreviewCompatibleImage imageInfo={awardItems.image4} />
          </div>
        </animated.div>
      </div>
      <div className="columns has-text-centered" style={{
        display: `flex`,
        flexWrap: `wrap`,
        alignItems: `center`
      }}>
        <div className="column">
          <div className="hm-mobile" style={{
                width: '80px',
                maxHeight: '300px',
                display: 'inline-block',
              }}>
            <PreviewCompatibleImage imageInfo={awardItems.image2} />
          </div>
        </div>
        <div className="column">
          <div className="hm-mobile" style={{
                width: '80px',
                maxHeight: '300px',
                display: 'inline-block',
              }}>
            <PreviewCompatibleImage imageInfo={awardItems.image3} />
          </div>
        </div>
        <div className="column">
          <div className="hm-mobile" style={{
              width: '100px',
              maxHeight: '300px',
              display: 'inline-block',
            }}>
            <PreviewCompatibleImage imageInfo={awardItems.image4} />
          </div>          
        </div>
      </div>      
    </div>
  )
}


export default Awards
