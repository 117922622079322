import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import TwoColumn from '../components/TwoColumn'
import ClientLogos from '../components/ClientLogos'
import BlogSlider from '../components/BlogSlider'
import Awards from '../components/Awards'
import downarrow from '../img/whyhuman-down-arrow.png'
import { BorderCurvesTop, BorderCurvesBottom } from '../components/BorderCurves'
import PreviewCompatibleBgImage from '../components/PreviewCompatibleBgImage'

export const WhyHumanPageTemplate = ({  
  title,
  metadescription,
  image,
  mainpitch,
  reasons, 
  awards,
  clientlogosbg,
  clientlogos,
  blogs,  
  helmet,
}) => (
  <div className="whyhuman-pg dark-bg-offset">  
    {helmet || ''}
    <div
      className="full-width-image margin-top-0 hero-bg"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top left`,
        justifyContent: 'left',
        flexWrap: `wrap`,
        height: '400px',
      }}
    >
      <div className="container" >        
        <div className="hero-text"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            maxWidth: '500px',
            lineHeight: '1',
            alignItems: 'left',
            flexDirection: 'column',
          }}> 
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><a href="/">Human</a></li>
              <li><span>Why Human</span></li>
            </ul>
          </nav> 
          <h1
            className="has-text-weight-bold"
            style={{            
              color: 'white',
              lineHeight: '1.2',
              padding: '0.25em',
            }}            
          >   {mainpitch.title} 
          </h1>                    
        </div>
      </div>
      <BorderCurvesBottom />      
    </div>
    <div className="sunny-side-up" style={{
        backgroundImage: 'url(/img/whyhuman-sunny-side.png)',
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`,
        height: '700px',
        width: '170px',
        position: 'absolute',
        top: '25%'
        }}></div>
    <section className="section section--gradient">      
      <div className="container">
        <div className="section" style={{padding: '0'}}>
          <div className="columns">
            <div className="column is-12">
              <div className="content hm-page">                
                <div className="columns reasons-section">
                  <div className="column is-3">                    
                  </div>
                  <div className="column is-8">
                    <TwoColumn gridItems={reasons} />
                  </div>
                </div>            
                <div className="has-text-centered why-human__downarrow"><img style={{maxWidth: `30px`}} src={downarrow} alt="why-human downarrow"/></div>                     
                <div className="columns awards">
                    <Awards awardItems={awards} />
                </div>
                                                        
                <BorderCurvesBottom />                       
                <PreviewCompatibleBgImage
                  bgTag="div"
                  bgClass={`hm-full-width intro-section-wrapper`}
                  imageInfo={clientlogosbg}
                  style={{            
                    backgroundRepeat: `no-repeat`,
                    backgroundSize: `cover`,                        
                  }}
                >                                                      
                  <BorderCurvesTop />
                  <div className="column client-logos">                 
                      <ClientLogos gridItems={clientlogos} />
                  </div>
                  <BorderCurvesBottom />                      
                </PreviewCompatibleBgImage>
                <div className="column is-12 blog-section">                  
                    <h2 className="has-text-weight-semibold carousel--header has-text-centered">Free marketing resources</h2>               
                    <BlogSlider sliderItems={blogs} />                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

WhyHumanPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  metadescription: PropTypes.string,
  mainpitch: PropTypes.object,
  reasons: PropTypes.array,  
  clientlogosbg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  clientlogos: PropTypes.array,
  blogs: PropTypes.array,
  awards: PropTypes.object,
  helmet: PropTypes.object,
}

const WhyHumanPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <div className="dark-bg-hero">
      <Layout>
        <WhyHumanPageTemplate
          image={frontmatter.image}
          title={frontmatter.title}      
          metadescription={frontmatter.metadescription}
          mainpitch={frontmatter.mainpitch}
          reasons={frontmatter.reasons}
          clientlogosbg={frontmatter.clientlogosbg}
          clientlogos={frontmatter.clientlogos}
          blogs={frontmatter.blogs}          
          awards={frontmatter.awards}
          helmet={
            <Helmet titleTemplate="%s | Human">
              <title>{`${frontmatter.title}`}</title>       
              <meta name="description" content={`${frontmatter.metadescription}`} />       
            </Helmet>
          }                    
        />
      </Layout>
    </div>
  )
}

WhyHumanPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default WhyHumanPage

export const pageQuery = graphql`
  query WhyHumanPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "whyhuman-page" } }) {
      frontmatter {
        title
        metadescription
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mainpitch {
          title
        }        
        reasons {
          header
          image {
            childImageSharp {
              fluid(maxWidth: 450, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          text          
        }        
        blogs {
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 64) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          linkurl
        }                
        awards {
          header
          blurb
          image1 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 100, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image2 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 80, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image3 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 100, quality: 64) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image4 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 120, quality: 64) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        clientlogosbg {          
          childImageSharp {
            fluid(maxWidth: 2048, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        } 
        clientlogos {
          image {
            childImageSharp {
              fluid(maxWidth: 180, quality: 64) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }        
      }
    }
  }
`
